@import '../mix/variables';

.card-shadow-serrilhado{
  max-width: 300px;
  width: calc(100% - 13px);
  margin-left: 13px;
  position: relative;

  //card como titulo
  &.card-shadow-serrilhado-tt{
    max-width: 263px;
    margin-top: -120px;
    margin-left: -170px;
    
    .content{
      position: relative;
    
      h2{
        font-size: 32px;
        text-transform: uppercase;
        font-weight: 700;
        color: #000;
        line-height: 1.6em;
        border-bottom: 2px solid #000;
        padding-bottom: 5px;
        margin-bottom: 0;
      }

      .circle-icon{
        position: absolute;
        top: -40px;
        right: -40px;
        width: 90px;
        height: 90px;
        padding: 15px;
        display: block;

        img{
            width: 100%;
            height: auto;
        }
      }

    }
  }

  // Definindo as cores do background do box e da borda serrilhada de baixo

  // WHITE
  &.border-bottom-serrilhado-white{
    &::before{
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1;
      background-color: $white;
      border-top: 2px solid $black;
      border-left: 2px solid $black;
      border-right: 2px solid $black;
    }
  
    &::after {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 13px;
      right: 0;
      bottom: -13px;
      left: 0;
      border-left: 2px solid $black;
      border-right: 2px solid $black;
      z-index: 2;
      background: linear-gradient(-30deg, transparent 58%, $black 66%, $white 73% ), 
                    linear-gradient( 30deg, transparent 58%, $black 66%, $white 73% );
      background-repeat: repeat-x;
      background-size: 37px 19px, 37px 19px;
      background-position: left;
    }
  }

  // PURPLE
  &.border-bottom-serrilhado-purple{
    &::before{
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1;
      background-color: $purple;
      border-top: 2px solid $black;
      border-left: 2px solid $black;
      border-right: 2px solid $black;
    }
  
    &::after {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 13px;
      right: 0;
      bottom: -13px;
      left: 0;
      border-left: 2px solid $black;
      border-right: 2px solid $black;
      z-index: 2;
      background: linear-gradient(-30deg, transparent 58%, $black 66%, $purple 73% ), 
                    linear-gradient( 30deg, transparent 58%, $black 66%, $purple 73% );
      background-repeat: repeat-x;
      background-size: 37px 19px, 37px 19px;
      background-position: left;
    }
  }

  // PINK
  &.border-bottom-serrilhado-pink{
    &::before{
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1;
      background-color: $pink;
      border-top: 2px solid $black;
      border-left: 2px solid $black;
      border-right: 2px solid $black;
    }
  
    &::after {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 13px;
      right: 0;
      bottom: -13px;
      left: 0;
      border-left: 2px solid $black;
      border-right: 2px solid $black;
      z-index: 2;
      background: linear-gradient(-30deg, transparent 58%, $black 66%, $pink 73% ), 
                    linear-gradient( 30deg, transparent 58%, $black 66%, $pink 73% );
      background-repeat: repeat-x;
      background-size: 37px 19px, 37px 19px;
      background-position: left;
    }
  }

  // LIME-GREEN
  &.border-bottom-serrilhado-lime-green{
    &::before{
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1;
      background-color: $lime-green;
      border-top: 2px solid $black;
      border-left: 2px solid $black;
      border-right: 2px solid $black;
    }
  
    &::after {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 13px;
      right: 0;
      bottom: -13px;
      left: 0;
      border-left: 2px solid $black;
      border-right: 2px solid $black;
      z-index: 2;
      background: linear-gradient(-30deg, transparent 58%, $black 66%, $lime-green 73% ), 
                    linear-gradient( 30deg, transparent 58%, $black 66%, $lime-green 73% );
      background-repeat: repeat-x;
      background-size: 37px 19px, 37px 19px;
      background-position: left;
    }
  }

  .shadow-serrilhada{
    position: absolute;
    top: -14px;
    left: -14px;
    height: calc(100% - 5px);
    width: 100%;
    border-top: 2px solid $black;
    border-left: 2px solid $black;
    border-right: 2px solid $black;
  }

  &.no-shadow{
    margin-left: 0;
    .shadow-serrilhada{
      display: none;
    }
  }

  // Definindo as cores da sombra serrilhada

  // TRANSPARENT
  &.shadow-serrilhada-transparent{
    
    .shadow-serrilhada{
      background-color: transparent;
    }

    .shadow-serrilhada::after {
      content: "";
      display: block;
      position: absolute;
      width: calc(100% + 4px);
      height: 13px;
      right: 0;
      bottom: -13px;
      left: -2px;
      border-left: 2px solid $black;
      border-right: 2px solid $black;
      background: linear-gradient(-30deg, transparent 58%, $black 66%, transparent 73% ), 
                    linear-gradient( 30deg, transparent 58%, $black 66%, transparent 73% );
      background-repeat: repeat-x;
      background-size: 37px 19px, 37px 19px;
      background-position: left;
    }
  }

  // YELLOW
  &.shadow-serrilhada-yellow{
    
    .shadow-serrilhada{
      background-color: $yellow;
    }

    .shadow-serrilhada::after {
      content: "";
      display: block;
      position: absolute;
      width: calc(100% + 4px);
      height: 13px;
      right: 0;
      bottom: -13px;
      left: -2px;
      border-left: 2px solid $black;
      border-right: 2px solid $black;
      background: linear-gradient(-30deg, transparent 58%, $black 66%, $yellow 73% ), 
                    linear-gradient( 30deg, transparent 58%, $black 66%, $yellow 73% );
      background-repeat: repeat-x;
      background-size: 37px 19px, 37px 19px;
      background-position: left;
    }
  }

  // PURPLE
  &.shadow-serrilhada-purple{
    
    .shadow-serrilhada{
      background-color: $purple;
    }

    .shadow-serrilhada::after {
      content: "";
      display: block;
      position: absolute;
      width: calc(100% + 4px);
      height: 13px;
      right: 0;
      bottom: -13px;
      left: -2px;
      border-left: 2px solid $black;
      border-right: 2px solid $black;
      background: linear-gradient(-30deg, transparent 58%, $black 66%, $purple 73% ), 
                    linear-gradient( 30deg, transparent 58%, $black 66%, $purple 73% );
      background-repeat: repeat-x;
      background-size: 37px 19px, 37px 19px;
      background-position: left;
    }
  }

  // LIME GREEN
  &.shadow-serrilhada-lime-green{
    
    .shadow-serrilhada{
      background-color: $lime-green;
    }

    .shadow-serrilhada::after {
      content: "";
      display: block;
      position: absolute;
      width: calc(100% + 4px);
      height: 13px;
      right: 0;
      bottom: -13px;
      left: -2px;
      border-left: 2px solid $black;
      border-right: 2px solid $black;
      background: linear-gradient(-30deg, transparent 58%, $black 66%, $lime-green 73% ), 
                    linear-gradient( 30deg, transparent 58%, $black 66%, $lime-green 73% );
      background-repeat: repeat-x;
      background-size: 37px 19px, 37px 19px;
      background-position: left;
    }
  }

  // WHITE
  &.shadow-serrilhada-white{
    
    .shadow-serrilhada{
      background-color: $white;
    }

    .shadow-serrilhada::after {
      content: "";
      display: block;
      position: absolute;
      width: calc(100% + 4px);
      height: 13px;
      right: 0;
      bottom: -13px;
      left: -2px;
      border-left: 2px solid $black;
      border-right: 2px solid $black;
      background: linear-gradient(-30deg, transparent 58%, $black 66%, $white 73% ), 
                    linear-gradient( 30deg, transparent 58%, $black 66%, $white 73% );
      background-repeat: repeat-x;
      background-size: 37px 19px, 37px 19px;
      background-position: left;
    }
  }

  // PINK
  &.shadow-serrilhada-pink{
    
    .shadow-serrilhada{
      background-color: $pink;
    }

    .shadow-serrilhada::after {
      content: "";
      display: block;
      position: absolute;
      width: calc(100% + 4px);
      height: 13px;
      right: 0;
      bottom: -13px;
      left: -2px;
      border-left: 2px solid $black;
      border-right: 2px solid $black;
      background: linear-gradient(-30deg, transparent 58%, $black 66%, $pink 73% ), 
                    linear-gradient( 30deg, transparent 58%, $black 66%, $pink 73% );
      background-repeat: repeat-x;
      background-size: 37px 19px, 37px 19px;
      background-position: left;
    }
  }
  
  // GREEN 4
  &.shadow-serrilhada-green-4{
    
    .shadow-serrilhada{
      background-color: $green_4;
    }

    .shadow-serrilhada::after {
      content: "";
      display: block;
      position: absolute;
      width: calc(100% + 4px);
      height: 13px;
      right: 0;
      bottom: -13px;
      left: -2px;
      border-left: 2px solid $black;
      border-right: 2px solid $black;
      background: linear-gradient(-30deg, transparent 58%, $black 66%, $green_4 73% ), 
                    linear-gradient( 30deg, transparent 58%, $black 66%, $green_4 73% );
      background-repeat: repeat-x;
      background-size: 37px 19px, 37px 19px;
      background-position: left;
    }
  }

  .content{
    position: relative;
    z-index: 5;
    padding: 24px 16px;
  }
}