@import '../mix/variables';

header {
  position: fixed;
  background-color: $dark;
  top: 0;
  width: 100%;
  z-index: 2024;

  nav{
    position: relative;

    // &::before{
    //   content: '';
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   background-color: $dark;
    //   width: 100%;
    //   height: calc(100% - 10px);
    // }

    .container{
      position: relative;
      padding-top: 35px;      
      padding-bottom: 35px;

      @media (max-width: 768px) {
        padding-top: 25px;        
        padding-bottom: 25px;
      }

      .logo{
        position: absolute;
        top: 0;
        left: 15px;
        z-index: 5;
        padding-top: 15px;

        img{
          transition: all .3s;
          width: 130px;
          height: 71x;
        }

        @media (max-width: 767.95px) {
          // .zoom-logo{
          //   transform: scale3d(1.92, 1.92 ,1.92);
          //   margin: 25px 50px;
          // }
          padding-top: 10px;
        }
        
        @media (min-width: 768px) and (max-width: 1199.95px) {
          // .zoom-logo{
          //   transform: scale3d(1.82, 1.82 ,1.82);
          //   margin: 25px 50px;
          // }
        }
      }

      .btn-toggle-navbar-mobile{
        background-color: $yellow;
        padding: 8px 6px;
        border-radius: 0;
        position: relative;
        z-index: 5;
        border: 2px solid $black;
        transition: all .3s;
  
        .div-bar{
          // row-gap: 4px;
          display: flex;
          flex-direction: column;
          transition: all .3s;
          
          &.close-icon{
            transform: translateX(5px);
          }
        }
  
        .traco-1,
        .traco-2,
        .traco-3 {
          height: 4px;
          width: 25px;
          background-color: $black;
          transition: all .2s;
          margin-bottom: 4px;

          &:last-child{
            margin-bottom: 0;
          }
        }
  
        .traco-1.close-icon{
          transform: rotate(45deg) translateY(9px) translateX(2px);
        }
        .traco-2.close-icon{
          opacity: 0;
        }
        .traco-3.close-icon{
          transform: rotate(-45deg) translateY(-9px) translateX(2.5px);
        }
  
        &.click-effect{
          transform: translateX(5px) translateY(5px);
        }
  
        &.click-effect::after, &.click-effect::before{
          opacity: 0;
        }
      }

      .navbar-collapse{
        flex-wrap: wrap;
        justify-content: flex-end;
        align-items: center;
        position: relative;
        z-index: 5;
        max-width: calc(100% - 130px);
  
        @media (max-width: 1199.95px) {
          position: absolute;
          top: 0;
          left: 0;
          max-width: none;
          min-height: 100vh;
          width: 100vw;
          padding: 200px 25px 100px 25px;
          z-index: -1;
          background-color: $dark;

          .nav-link{
            padding: 8px 20px !important;
          }

          .row {
            max-width: 330px;
          }
        }

      
        .nav-link{
          border: 2px solid $black;
          position: relative;
          padding: 5px 10px;
          color: $black;
          font-weight: 900;
          font-size: 13px;
          text-transform: uppercase;
          transition: all .3s;
      
          &:hover{
            transform: translateX(5px) translateY(5px);
            text-decoration: none;
          }

          @media (max-width: 1360px) {
            font-size: 12px;
          }

          @media (max-width: 1285px) {
            font-size: 11px;
          }

          @media (max-width: 1199.95px) {
            font-size: 14px;
          }
        }
      }
    }
  }
}