@import '../mix/variables';

footer{
  margin-top: 300px;
  padding: 0 0 35px 0;
  background-color: $lime-green;

  @media (max-width: 576px) {
    background-color: $green_2;
    padding: 0 0 30px 0;
  }

  .container{
    .row{
      align-items: flex-start;
      justify-content: center;
      column-gap: 100px;
      margin-top: 40px;

      @media (max-width: 576px) {
        margin-top: 10px;
      }

      .card-shadow-serrilhado{
        margin-top: 100px;

        .content{
          p{
            font-size: 28px;
            text-transform: uppercase;
            font-weight: 700;
            color: $black;
            line-height: 120%;
            border-bottom: 2px solid $black;
            padding-bottom: 5px;
          }

          a{
            display: block;
            margin-top: 17px;
            text-transform: uppercase;
            color: $black !important;
            padding-bottom: 3px;
            font-size: 14px;
            font-weight: 700;
            border-bottom: 2px dotted $black;

            &:hover{
              text-decoration: none;
            }
          }
          
          span{
            padding: 0 3px;
          }
        }
      }
    }

    .conjunto-logo-redes{
      width: 100%;
      max-width: 470px;
      margin-top: -80px;

      @media (max-width: 576px) {
        max-width: 300px;
        margin-top: -150px;
        position: relative;

        &::before{
          content: '';
          position: absolute;
          top: 50px;
          right: 50%;
          height: 100%;
          width: 100vw;
          background-color: $green_2;
        }
      }

      .caixa-logo{
        padding: 60px 15px;
        background-color: $verde-logo;
        display: flex;
        justify-content: center;
        align-items: center;
        width: calc(100% - 100px);
        position: relative;
        border: 2px solid $black;
        z-index: 5;

        @media (max-width: 576px) {
          padding: 25px 35px;
        }

        img{
          max-width: 100%;
        }
      }

      .redes-copy{
        margin-top: -45px;
        width: calc(100% - 100px);
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;

        @media (max-width: 576px) {
          margin-top: 10px;
          float: right;
          width: 100%;          
          align-content: flex-end;
          align-items: flex-end;
        }

        span{
          margin-bottom: 6px;
          font-weight: 500;
          position: relative;
          z-index: 9;
          color: #fff;

          @media (max-width: 576px) {
            display: none;
          }
        }

        .caixa-redes{
          padding: 8px 20px;
          position: relative;
          z-index: 5;
          border: 2px solid $black;
          display: flex;
          column-gap: 10px;

          
          img{
            width: 30px;
            min-width: 30px;
            height: 30px;
            max-height: 30px;
          }
          
          @media (max-width: 576px) {
            flex-wrap: wrap;
            padding: 5px 10px;
          }

          img{
            @media (max-width: 576px) {
              width: 25px;
              min-width: 25px;
              height: 25px;
              max-height: 25px;
              margin: 5px 0px;
            }
          }
        }
      }
    }
  }
}

.cookies-msg{
  background-color: $yellow;
  padding: 30px 25px;
  width: 100%;
  max-width: 450px;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 3500;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .title{
    color: $white;
    text-transform: uppercase;
    font-size: 20px;
    margin-bottom: 0;
    line-height: 110%;
    width: 100%;

    @media (max-width: 575.95px) {
      font-size: 18px;
    }

    img{
      height: auto;
      width: auto;
    }
  }
  .text{
    width: 100%;
    margin-top: 10px;
    color: $black;
    line-height: 110%;
    text-transform: uppercase;
    font-size: 18px;
    margin-bottom: 0;

    @media (max-width: 575.95px) {
      font-size: 15px;
    }

    a{
      color: $white !important;
      text-decoration: underline;
      margin-left: 3px;
    }
  }

  button{
    width: fit-content;
    background-color: $white;
    padding: 8px 17px 5px;
    color: $black !important;
    border: 2px solid $black;
    text-transform: uppercase;
    font-weight: 700;
    line-height: 110%;
    font-size: 20px;
    transition: all .3s;
    margin-top: 15px;
  }
}