@import '../mix/variables';

.borda{
  border: 1px solid red;
}

.title-gradient{
  font-size: 70px;
  background: -webkit-linear-gradient(150deg, $blue_3 30%, $lime-green 70%);
  background-clip: text;
  -webkit-background-clip: text;
  line-height: 110%;
  -webkit-text-fill-color: transparent;
  width: fit-content;
  text-transform: uppercase;

  @media (max-width: 579.95px) {
    font-size: 40px;

    br{
      display: none;
    }
  }
}

.font-encode-sans{
  font-family: 'Encode Sans Expanded', sans-serif !important;
}

.stretched-link{
  &::after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}

.small{
  font-size: 12px !important;
}

@font-face {
  font-family: 'Saint Regus';
  src: url(../assets/fonts/saint-regus/SaintRegusSemiBold.otf);
}

.font-saint-regus{
  font-family: 'Saint Regus', sans-serif;
}

@font-face {
  font-family: 'Tanker';
  src: url(../assets/fonts/tanker/TankerRegular.otf);
}

.font-tanker{
  font-family: 'Tanker', sans-serif !important;
}

.arrow-left{
  transform: rotate(-180deg);
}

.bg-transparent{
  background: transparent !important;
}

.btn{
  background: transparent;
  border: none;
}

.order-2 {
  -ms-flex-order: 2;
  order: 2;
}

.tag{
  padding: 6px 15px 4px 15px;
  min-width: 175px;
  border: 2px solid $black;
  text-align: center;
  font-weight: 900;
  text-transform: uppercase;
  color: $black !important;
  font-size: 13px;
  display: block;
  width: fit-content;
}

h1, h2, h3, h4, h5, h6, p{
  word-break: break-word;
}

h1, h2{
  padding-top: 22px;
}

.circle-icon{
  border-radius: 50% !important;
}

@media (min-width: 576px) and (max-width: 1400px) {
  #header nav .container{
    max-width: 100% !important;
  }
  .container{
    max-width: calc(100% - 50px) !important;
  }
}

@media (min-width: 1400px) {
  .container{
    max-width: 1366px !important;
  }
}

h2{
  font-size: 29px;
}

.bg-lime-green{
  background-color: $lime-green;
}
.bg-blue-1{
  background-color: $blue_1;
}
.bg-blue-2{
  background-color: $blue_2;
}
.bg-blue-3{
  background-color: $blue_3;
}
.bg-purple{
  background-color: $purple;
}
.bg-yellow{
  background-color: $yellow;
}
.bg-white{
  background-color: $white;
}
.bg-grid-white{
  background: url(assets/images/grid-bg.png), $white !important;
  background-size: 140px !important;
}

.text-lime-green{
  color: $lime-green;
}
.text-blue-1{
  color: $blue_1;
}
.text-blue-2{
  color: $blue_2;
}
.text-blue-3{
  color: $blue_3;
}
.text-purple{
  color: $purple;
}
.text-purple-2{
  color: $purple_2;
}
.text-yellow{
  color: $yellow;
}
.text-gray{
  color: $gray_opac !important;
}
.text-white{
  color: $white;
}
.text-gradient-1{
  background: -webkit-linear-gradient(150deg, $blue_2 50%, $lime-green 80%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@keyframes AnimateStar {
  from{
    transform: scale3d(0.8, 0.8, 0.8) rotate(0deg);
  }
  30%{
    transform: scale3d(1.3, 1.3, 1.3) rotate(-108deg);
  }
  to{
    transform: scale3d(0.8, 0.8, 0.8) rotate(-360deg);
  }
}

@keyframes AnimateStarReverse {
  from{
    transform: scale3d(0.8, 0.8, 0.8) rotate(0deg);
  }
  30%{
    transform: scale3d(1.3, 1.3, 1.3) rotate(108deg);
  }
  to{
    transform: scale3d(0.8, 0.8, 0.8) rotate(360deg);
  }
}

@media (max-width: 767.95px) {
  @keyframes AnimateStar {
    from{
      transform: scale3d(0.5, 0.5, 0.5) rotate(0deg);
    }
    30%{
      transform: scale3d(0.9, 0.9, 0.9) rotate(-108deg);
    }
    to{
      transform: scale3d(0.5, 0.5, 0.5) rotate(-360deg);
    }
  }
  
  @keyframes AnimateStarReverse {
    from{
      transform: scale3d(0.5, 0.5, 0.5) rotate(0deg);
    }
    30%{
      transform: scale3d(0.9, 0.9, 0.9) rotate(108deg);
    }
    to{
      transform: scale3d(0.5, 0.5, 0.5) rotate(360deg);
    }
  }
}

.animate-srtar{
  animation-name: AnimateStar;
  animation-duration: 7s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.animate-srtar-reverse{
  animation-name: AnimateStarReverse;
  animation-duration: 7s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

// list posts
.list-content-post{
  li{
    transition: all .3s;
    margin-bottom: 40px;
    a{
      transition: all .3s;
      font-family: 'Saint Regus';
      font-weight: 600;
      font-size: 24px;
      line-height: 1.2em;
      text-transform: uppercase;
      display: flex;
      align-items: center;

      .tt-post{
        font-family: 'Saint Regus';
        font-weight: 600;
        font-size: 24px;
        line-height: 1em;
        line-height: 1.2em;
        padding-right: 16px;
        transition: all .3s;
      }

      &:hover{
        color: $white;
        .tt-post{
          text-decoration: underline;
        }
      }
    }
  }
}

.list-post{
  display: flex;
  margin-top: 30px;

  li{
    transition: all .3s;
    position: relative;
    display: flex;
    margin-bottom: 30px;

    img{
      object-fit: cover;
      width: 175px;
      min-width: 175px;
      height: 201px;
      max-height: 201px;
    }
    
    a{
      transition: all .3s;
      &.lk-box{
        &::after {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 1;
          pointer-events: auto;
          background-color: rgba(0, 0, 0, 0);
        }
      }
    }

    .block-color{
      padding: 6px 20px 3px;
      border: 2px solid $black;
      width: fit-content;
      margin-bottom: 16px;
      color: $black;
      font-family: 'Saint Regus';
      font-weight: 600;
      font-size: 16px;
      line-height: 1.2em;
      text-transform: uppercase;
      margin-right: 16px;
    }

    .tt-post{
      transition: all .3s;
      font-family: 'Saint Regus';
      padding-right: 10px;
      font-weight: 600;
      font-size: 24px;
      line-height: 1.2em;
      color: $white;
      text-transform: uppercase;
    }

    .block-date{
      font-family: 'Encode Sans Expanded', sans-serif !important;
      font-size: 12px;
      font-weight: 400;
      color: #DBD9DE;
      margin: -5px 0 10px;
    }

    &:hover{
      .tt-post{
        color: $white;
        text-decoration: underline;
      }
    }
  }

  &.row{
    margin-right: -25px;
    margin-left: -25px;

    li{
      padding-right: 25px;
      padding-left: 25px;
    }
  }

  @media (max-width: 1220px) {
    li{
      margin-bottom: 60px;
      padding-right: 15px;
      padding-left: 15px;

      .box-text{
        margin-right: 10px;
        
        .block-color{
          font-size: 13px;
          margin-right: 0;
        }

        .tt-post{
          padding-right: 0;
          font-size: 20px;
        }
      }
    
      // a{
      //   &.lk-box{
      //     img{
      //       width: 145px;
      //       height: auto;
      //     }
      //   }
      // }
    }
  }

  @media (max-width: 1199px) {
    
    &.row{
      margin-right: -15px;
      margin-left: -15px;
      
      li{
        justify-content:space-between;
        padding-right: 15px;
        padding-left: 15px;
      }
    }
  }

  @media (max-width: 991px) {
    li{
      margin-bottom: 30px;

      .box-text{
        margin-right: 10px;
        .block-color{
          font-size: 16px;
          margin-right: 16px;
        }

        .tt-post{
          padding-right: 10px;
          font-size: 24px;
        }
      }
    
      // a{
      //   &.lk-box{
      //     img{
      //       width: 360px;
      //       height: auto;
      //     }
      //   }
      // }
    }
  }

  // @media (max-width: 640px) {
  //   li{
  //     flex-direction: column;
  //     margin-bottom: 35px;

  //     a{
  //       &.lk-box{
  //         img{
  //           width: 100%;
  //           height: auto;
  //           margin-top: 10px;
  //         }
  //       }
  //     }
  //   }
  // }

  @media (max-width: 425px) {
    li{
      margin-bottom: 60px;

      .box-text{
        margin-right: 10px;
        .block-color{
          font-size: 13px;
          margin-right: 0;
          margin-bottom: 8px;
        }

        .tt-post{
          padding-right: 0;
          font-size: 18px;
          margin-bottom: 0;
        }

        .block-date{
          margin: 0 0 5px;
        }
      }
    
      a{
        &.lk-box{
          img{
            width: 145px;
            min-width: 145px;
            height: 161px;
            max-height: 161px;
          }
        }
      }
    }
  }
}

// Swiper webstories

.swiperWebstories{
  .swiper-wrapper{
    padding: 30px 0;
    margin-top: 70px;

    @media (max-width: 575.95px) {
      margin-top: 50px;
    }

    .swiper-slide{
      min-height: 450px;
      min-width: 240px;
      width: calc(25% - 40px);
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      transition: transform .3s;

      &:last-child{
        margin-right: 25px;
      }

      @media (max-width: 499.95px) {
        width: 66%;
      }
      @media (min-width: 499.95px) and (max-width: 669.95px) {
        width: 49%;
      }
      @media (min-width: 669.95px) and (max-width: 767.95px) {
        width: 39%;
      }
      @media (min-width: 767.95px) and (max-width: 991.95px) {
        width: 32%;
      }
      @media (min-width: 991.95px) and (max-width: 1199.95px) {
        width: 25%;
      }

      &.title-bottom{
        justify-content: flex-end;

        a{
          background: linear-gradient(0deg,#000,#000c,transparent);
          padding: 70px 15px 15px;
        }
      }

      &:hover{
        transform: translateX(5px) translateY(5px);

        a{
          text-decoration: none;
        }
      }

      .icon{
        position: absolute;
        top: -32px;
        right: 15px;
        width: 45px;
        height: 45px;
        border-radius: 50%;
        background-color: $yellow;
        border: 1px solid $black;
        display: flex;
        z-index: 3;
        justify-content: center;
        align-items: center;
        font-size: 25px;
      }

      img.banner{
        position: absolute;
        top: 0;
        height: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      a{
        padding: 15px;
        background: linear-gradient(-180deg, #4E4E4E, transparent);
        z-index: 1;
        display: block;

        .paragraph{
          color: $white;
          text-transform: uppercase;
          letter-spacing: .5px;
          display: block;
          font-family: 'Encode Sans Expanded', sans-serif;
          font-weight: 700;
          font-size: 15px;
        }
        .title{
          color: $white;
          font-size: 28px;
          line-height: 115%;
          margin-top: 5px;
          text-transform: uppercase;
          display: block;
        }
      }
    }
  }
}

.page-404{
  .animation-404{
    padding: 8px 0;
    display: flex;
    overflow: hidden;
  
    ul{
      padding: 0 !important;
      li{
        display: flex;
        list-style: none;
        margin-left: 15px;
      }
    }
  
    .div-passador{
      overflow: hidden;
      display: flex;
      min-width: max-content;
      min-width: -webkit-max-content;
    }
  
    .div-passador.animate{
      animation-name: Animate404;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
    }
  
    .div-passador.reverse-animation{
      animation-direction: reverse;
    }
  
    p{
      font-size: 40px;
      text-transform: uppercase;
      min-width: max-content;
      font-family: 'Tanker', sans-serif;
      letter-spacing: 1px;
      margin-bottom: 0;
      font-weight: 500;
  
      @media (max-width: 768px) {
        font-size: 25px;
      }
    }
  
    span{
      font-size: 35px;
      line-height: 145%;
      margin: 0 7px 0 19px;
      color: $white;
    }
  
    .order-2 {
      -ms-flex-order: 2;
      order: 2;
    }
  }

  .erro-404{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-end;
    column-gap: 70px;
    margin-top: 150px;

    @media (max-width: 575.95px) {
      margin-top: 110px;
      column-gap: 23px;
    }
    @media (min-width: 575.95px) and (max-width: 991.95px) {
      column-gap: 50px;
    }

    span{
      font-size: 550px;
      line-height: 50%;
      color: $yellow;

      @media (max-width: 575.95px) {
        font-size: 170px;
      }

      @media (min-width: 575.95px) and (max-width: 991.95px) {
        font-size: 300px;
      }
    }

    img{
      width: 200px;
      height: auto;
      object-fit: contain;

      @media (max-width: 575.95px) {
        width: 85px;
      }
      @media (min-width: 575.95px) and (max-width: 991.95px) {
        width: 150px;
      }
    }
    
    a{
      position: relative;
      display: flex;
      align-items: center;
      column-gap: 10px;
      width: fit-content;
      background-color: $white;
      padding: 10px 22px 5px;
      color: $black !important;
      text-transform: uppercase;
      font-weight: 700;
      line-height: 110%;
      font-size: 30px;
      transition: all .3s;
      text-align: center;

      @media (max-width: 575.95px) {
        font-size: 25px;
      }
    }
  }
  
  @keyframes Animate404 {
    from{
      transform: translateX(0%);
    }
    to{
      transform: translateX(-100%);
    }
  }
}

.error404{
  footer{
    margin-top: 220px;
  }
}

.btn-search-fixed{
  position: fixed;
  right: 0;
  z-index: 1000;
  bottom: 20%;
  height: 60px;
  width: 60px;
  background-color: $yellow;
  border-radius: 50px 0 0 50px;
  display: flex;
  justify-content: center;
  align-items: center;

  img{
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
  }
}

.fullscreen-GPBB{
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 3000;
  background: url(assets/images/grid-bg.png), $dark !important;
  background-size: 140px !important;
  padding: 50px 0;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  row-gap: 40px;

  .group-btn{
    display: flex;
    justify-content: flex-end;

    .btn-close-modal-GPBB{
      background-color: $yellow;
      padding: 8px 6px;
      border-radius: 0;
      position: relative;
      z-index: 5;
      border: 2px solid $black;
      transition: all .3s;

      .div-bar{
        row-gap: 4px;
        display: flex;
        flex-direction: column;
        transition: all .3s;
        
        &.close-icon{
          transform: translateX(5px);
        }
      }

      .traco-1,
      .traco-2,
      .traco-3 {
        height: 4px;
        width: 25px;
        background-color: $black;
        transition: all .2s;
      }

      .traco-1.close-icon{
        transform: rotate(45deg) translateY(9px) translateX(2px);
      }
      .traco-2.close-icon{
        opacity: 0;
      }
      .traco-3.close-icon{
        transform: rotate(-45deg) translateY(-9px) translateX(2.5px);
      }

      &.click-effect{
        transform: translateX(5px) translateY(5px);
      }

      &.click-effect::after, &.click-effect::before{
        opacity: 0;
      }
    }
  }

  .container-form{
    margin: auto;
    form{
      display: flex;
      padding-bottom: 7px;
      border-bottom: 2px solid $yellow;
      column-gap: 15px;

      input{
        font-size: 25px;
        background: transparent;
        border: none;
        outline: none;
        font-weight: 500;
        color: $yellow;
        font-family: 'Encode Sans Expanded';

        &::placeholder{
          text-transform: uppercase;
          font-size: 25px;
          font-weight: 500;
          color: $yellow;
          font-family: 'Encode Sans Expanded';
        }
      }

      button{
        width: 35px;
        height: 35px;
      }
    }
  }
}

.page-GPBB{
  margin-top: 130px;

  form{
    display: flex;
    padding-bottom: 7px;
    border-bottom: 2px solid $yellow;
    column-gap: 15px;
    max-width: 900px;
    margin: 0 auto;

    input{
      font-size: 25px;
      background: transparent;
      border: none;
      outline: none;
      font-weight: 500;
      color: $yellow;
      font-family: 'Encode Sans Expanded';

      &::placeholder{
        text-transform: uppercase;
        font-size: 25px;
        font-weight: 500;
        color: $yellow;
        font-family: 'Encode Sans Expanded';
      }
    }

    button{
      width: 35px;
      height: 35px;
    }
  }

  .result{
    max-width: 900px;
    color: white;
    font-family: 'Encode Sans Expanded';
    margin: 15px auto 0;
    display: block;
  }

  ul{
    list-style: none;
    padding: 0;
    max-width: 900px;
    margin: 80px auto 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 70px;
    row-gap: 90px;

    @media (max-width: 767.95px) {
      grid-template-columns: 1fr;
      max-width: 420px;
    }

    li{
      display: flex;
      justify-content: space-between;
      position: relative;
      
      .text{
        width: calc(50% - 10px);
        margin-bottom: -10px;

        @media (max-width: 399.95px) {
          margin-bottom: -5px;
        }

        .tag{
          padding: 3px 15px 0;
          min-width: calc(min(100%, 125px));
          max-width: 100%;
          font-size: 17px;
        }

        .date{
          color: $white;
          opacity: .5;
          font-size: 12px;
          font-weight: 500;
          margin: 8px 0 3px;
          display: block;
        }

        a{
          font-size: 25px;
          color: $white;
          text-transform: uppercase;
          margin-bottom: 0;
          display: block;

          @media (max-width: 399.95px) {
            font-size: 22px;
          }

          &::after{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
          }
        }
      }

      .img{
        width: calc(50% - 10px);
        height: 200px;

        img{
          width: 100%;
          height: 100%;
          max-width: 100%;
          max-height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}