@import '../mix/variables';

//cutomização botões geral
.bt{
  position: relative;
  display: flex;
  align-items: center;
  column-gap: 10px;
  width: fit-content;
  background-color: #fff;
  padding: 7px 15px 9px;
  color: #000!important;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 110%;
  font-size: 28px;
  transition: transform .2s,opacity .2s;
}

button:focus, .btn:focus{
  box-shadow: none;
}

.hover-button-press:hover{
  transform: translateX(5px) translateY(5px);
  text-decoration: none;
}

.hover-button-press:hover::after, .hover-button-press:hover::before{
  opacity: 0;
}

// Sombras dos botões
.custom-shadow{
  border: 2px solid $black;
  position: relative;

  &::after{
    content: '';
    transition: all .2s;
    position: absolute;
    right: -16px;
    top: 5px;
    height: calc(100% + 2px);
    width: 16px;
    border-top: 2px solid $black;
    border-left: 2px solid $black;
    border-right: 2px solid $black;
    z-index: 0;
    transform: skewY(42deg);
  }
  &::before{
    content: '';
    transition: all .2s;
    position: absolute;
    bottom: -15px;
    left: 4px;
    width: calc(100% + 6px);
    height: 15px;
    border-top: 2px solid $black;
    border-bottom: 2px solid $black;
    border-left: 2px solid $black;
    z-index: 0;
    transform: skewX(40deg);
  }
}

.custom-shadow-sm{
  border: 2px solid $black;
  position: relative;

  &::after{
    content: '';
    transition: all .2s;
    position: absolute;
    right: -9px;
    top: 2px;
    height: calc(100% + 2px);
    width: 9px;
    border-top: 2px solid $black;
    border-left: 2px solid $black;
    border-right: 2px solid $black;
    z-index: 0;
    transform: skewY(42deg);
  }
  &::before{
    content: '';
    transition: all .2s;
    position: absolute;
    bottom: -8px;
    left: 2px;
    width: calc(100% + 3px);
    height: 8px;
    border-top: 2px solid $black;
    border-bottom: 2px solid $black;
    border-left: 2px solid $black;
    z-index: 0;
    transform: skewX(42deg);
  }
}

.custom-shadow-xl{
  border: 2px solid $black;
  position: relative;

  &::after{
    content: '';
    transition: all .2s;
    position: absolute;
    right: -20px;
    top: 8px;
    height: calc(100% + 2px);
    width: 20px;
    border-top: 2px solid $black;
    border-left: 2px solid $black;
    border-right: 2px solid $black;
    z-index: 0;
    transform: skewY(43deg);
  }
  &::before{
    content: '';
    transition: all .2s;
    position: absolute;
    bottom: -19px;
    left: 8px;
    width: calc(100% + 3px);
    height: 19px;
    border-top: 2px solid $black;
    border-bottom: 2px solid $black;
    border-left: 2px solid $black;
    z-index: 0;
    transform: skewX(43deg);
  }
}

// Cores das sombras dos botões

.custom-shadow, .custom-shadow-sm, .custom-shadow-xl {
  // black
  &.shadow-black::after{
    background-color: $black;
  }
  &.shadow-black::before{
    background-color: $black;
  }

  // lime-green
  &.shadow-lime-green::after{
    background: $lime-green;
  }
  &.shadow-lime-green::before{
    background: $lime-green;
  }

  // white
  &.shadow-white::after{
    background: $white;
  }
  &.shadow-white::before{
    background: $white;
  }

  // yellow
  &.shadow-yellow::after{
    background: $yellow;
  }
  &.shadow-yellow::before{
    background: $yellow;
  }

  // blue-1
  &.shadow-blue-1::after{
    background: $blue_1;
  }
  &.shadow-blue-1::before{
    background: $blue_1;
  }
  // blue-2
  &.shadow-blue-2::after{
    background: $blue_2;
  }
  &.shadow-blue-2::before{
    background: $blue_2;
  }
  // blue-3
  &.shadow-blue-3::after{
    background: $blue_3;
  }
  &.shadow-blue-3::before{
    background: $blue_3;
  }
  // purple
  &.shadow-purple::after{
    background: $purple;
  }
  &.shadow-purple::before{
    background: $purple;
  }
}

.group-link-ballon{
  width: fit-content;
  position: relative;
  align-items: center;
  transition: all .3s;
  cursor: pointer;

  @media (max-width: 576px) {
    justify-content: center;
  }
  
  .link-ballon{
    width: fit-content;
    background-color: $white;
    padding: 11px 30px 2px 30px;
    color: $black !important;
    text-transform: uppercase;
    text-align: center;
    position: relative;
    font-weight: 700;
    line-height: 110%;
    font-size: 35px;
    transition: transform .2s, opacity .2s;
  
    @media (max-width: 576px) {
      width: auto;
      z-index: 3;
      font-size: 30px;
      padding: 8px 30px 3px 30px;
    }
  
    &:hover{
      text-decoration: none;
    }
  }

  .msg{
    position: absolute;
    z-index: 2;
    cursor: default;

    @media (max-width: 576px) {
      right: unset !important;
      z-index: 0;
      bottom: calc(100% - 13px);
    }

    .content{
      height: 100%;
      width: 100%;
      padding: 30px;
      position: relative;
      transform: rotate(13deg);

      .text{
        color: $black;
        font-size: 11px;
        font-weight: 800;
        text-transform: uppercase;
        width: auto;
        height: auto;
        max-height: 100%;
        line-height: 117%;
        text-align: center;
        position: relative;
        margin-bottom: 0;
        z-index: 2;
      }

      &::before{
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background: url('assets/images/circle-serrilhado.png') center center no-repeat;
        background-size: contain;
      }
    }
  }
}


.lk-box{
  max-width: 600px;
  &.txt-destaque{
    margin-top: 120px;
    span{
      font-family: 'Encode Sans Expanded', sans-serif !important;
      font-size: 17px;
      font-weight: 800;
      line-height: 1.2em;
      color: $dark;
      position: absolute;
      bottom: calc(100% - 20px);
      width: 160px;
      height: 160px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 30px;
      border-radius: 50%;
      transform: rotate(13deg);
      z-index: -2;
      background: url('assets/images/circle-serrilhado.png') center center no-repeat;
      background-size: contain;
    }
    
    // &:hover{
    //   transform: none;
      
    //   &::before, &::after{
    //     opacity: 1;
    //   }
     
    // }
  }
}