$lime-green: #4EF0BC;
$green_2: #28C1BE;
$green_3: #1B9A97;
$green_4: #04FBF5;
$blue_1: #12DBD4;
$blue_2: #04B8F0;
$blue_3: #057CF0;
$purple: #817FFA;
$purple_2: #AB42EC;
$yellow: #FFC204;
$black: #000000;
$black_opac: rgba(0, 0, 0, 0.315);
$gray_opac: rgba(0, 0, 0, 0.528);
$dark: #1a1a1a;
$dark_2: #3b3b3b;
$white: #ffffff;
$light: #FCFCFB;
$verde-logo: #1B9A97;
$pink: #F863CB;

