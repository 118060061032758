@charset "UTF-8";
@import 'mix/variables';
@import 'components/boxmsg';
@import 'components/header';
@import 'components/footer';
@import 'components/btn';
@import 'components/geral';
@import 'components/modal';
@import 'misc/fonts';

body{
  background: url(assets/images/grid-bg.png), $dark !important;
  background-size: 140px !important;
  padding-top: 100px;
  font-family: 'Saint Regus', sans-serif !important;
}

body, html{
  scroll-behavior: smooth;
  scroll-padding-top: 150px;
}

[class*="fullscreen-"] {
  background: url(assets/images/grid-bg.png), $dark !important;
  background-size: 140px !important;
}

ul, li{
  list-style: none;
  margin: 0;
  padding: 0;
}

// Retirada da barra de navegação nativa do WP
#wpadminbar{
  display: none !important;
}

html{
  margin-top: 0 !important;
}

.item-stories{
  a{
    &::after{
        content: url('assets/images/play.png');
    }
  }
}
